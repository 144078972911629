import {Box, Button, Divider, Drawer, Stack, Theme, Typography, useMediaQuery} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {SideNavItem} from "./sidenavItem";

const items = [
    {
        title: "Bookings",
        path: "/bookings",
        icon: null,
    },
    {
        title: "Organizations",
        path: "/organizations",
        icon: null,
    },
    {
        title: "Clinics",
        path: "/clinics",
        icon: null,
    },
];

export const SideNav = () => {
    const {pathname} = useLocation();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

    const handleExit = () => {
        localStorage.clear();
        window.location.href = "/"
    };

    const content = (
        <Box
            sx={{
                height: "100%",
                "& .simplebar-content": {
                    height: "100%",
                },
                "& .simplebar-scrollbar:before": {
                    background: "neutral.400",
                },
            }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}>
                <Box sx={{p: 3}}>
                    <Box
                        component={Link}
                        to="/bookings"
                        sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            height: 32,
                            width: 32,
                            textDecoration: "none",
                        }}>
                        <img src="/favicon.svg" alt=""/>
                        <Typography sx={{color: "white", fontSize: "24px", fontWeight: 500, ml: 1}}>
                            Medinexus
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{borderColor: "neutral.700"}}/>
                <Box
                    component="nav"
                    sx={{
                        flexGrow: 1,
                        px: 2,
                        py: 3,
                    }}>
                    <Stack
                        component="ul"
                        spacing={0.5}
                        sx={{
                            listStyle: "none",
                            p: 0,
                            m: 0,
                        }}>
                        {items.map(item => {
                            const active = item.path ? pathname === item.path : false;

                            return <SideNavItem active={active} key={item.title} path={item.path} title={item.title}/>;
                        })}
                    </Stack>
                </Box>
                <Divider sx={{borderColor: "neutral.700"}}/>
                <Box
                    sx={{
                        px: 2,
                        py: 3,
                    }}>
                    <Button fullWidth sx={{mt: 2}} onClick={handleExit} color="primary" variant="contained">
                        Exit
                    </Button>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Drawer
            anchor="left"
            open={false}
            PaperProps={{
                sx: {
                    backgroundColor: "#1C2536",
                    color: "white",
                    width: 280,
                },
            }}
            sx={lgUp ? {} : {zIndex: theme => theme.zIndex.appBar + 100}}
            variant={lgUp ? "permanent" : "temporary"}>
            {content}
        </Drawer>
    );
};
