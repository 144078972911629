import {Box, ButtonBase} from "@mui/material";
import {FC, ReactNode} from "react";
import {Link} from "react-router-dom";

interface Props {
    active?: boolean;
    disabled?: boolean;
    external?: boolean;
    icon?: ReactNode;
    path?: string;
    title: string;
}

export const SideNavItem: FC<Props> = props => {
    const {active = false, icon, path, title} = props;

    const linkProps = {
        component: Link,
        to: path,
    };

    return (
        <li>
            <ButtonBase
                sx={{
                    alignItems: "center",
                    borderRadius: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    pl: "16px",
                    pr: "16px",
                    py: "6px",
                    textAlign: "left",
                    width: "100%",
                    ...(active && {
                        backgroundColor: "rgba(255, 255, 255, 0.04)",
                    }),
                    "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.04)",
                    },
                }}
                {...linkProps}>
                {icon && (
                    <Box
                        component="span"
                        sx={{
                            alignItems: "center",
                            color: "rgb(157, 164, 174)",
                            display: "inline-flex",
                            justifyContent: "center",
                            mr: 2,
                            ...(active && {
                                color: "primary.main",
                            }),
                        }}>
                        {icon}
                    </Box>
                )}
                <Box
                    component="span"
                    sx={{
                        color: "white",
                        flexGrow: 1,
                        fontFamily: theme => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "24px",
                        whiteSpace: "nowrap",
                        ...(active && {
                            color: theme => theme.palette.primary.main,
                        }),
                    }}>
                    {title}
                </Box>
            </ButtonBase>
        </li>
    );
};
