import {styled} from "@mui/material/styles";
import {SideNav} from "./components/sidenav";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {TOKEN} from "../../utils/constants";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({theme}) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
        paddingLeft: SIDE_NAV_WIDTH,
    },
}));

const LayoutContainer = styled("div")({
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    marginLeft: "10px",
});

export const Layout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem(TOKEN);
        if (!token) {
            navigate("/");
        }
    }, [navigate]);

    return (
        <>
            <SideNav />
            <LayoutRoot>
                <LayoutContainer>
                    <Outlet />
                </LayoutContainer>
            </LayoutRoot>
        </>
    );
};
