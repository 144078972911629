import {Route, Routes} from "react-router-dom";
import {Layout} from "../layouts/main";

import Bookings from "../pages/bookings";
import Organizations from "../pages/organizations";
import Login from "../pages/login";
import Clinics from "../pages/clinics";
import ManagersByOrganizationId from "../pages/managersByOrganizationId";

export const Router = () => (
    <Routes>
        <Route index element={<Login />} />
        <Route path="/" element={<Layout />}>
            <Route path="organizations" element={<Organizations />} />
            <Route path="bookings" element={<Bookings />} />
            <Route path="clinics" element={<Clinics />} />
            <Route path="managers/:organizationId" element={<ManagersByOrganizationId />} />
        </Route>
    </Routes>
);
