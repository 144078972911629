import CssBaseline from "@mui/material/CssBaseline";
import {Router} from "./routers";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";

function App() {
    const theme = createTheme();
    return (
        <div className="App">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router />
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
