import {lazy, Suspense} from "react";

const Page = lazy(async () => {
    return await import("./Page").then(res => ({default: res.default}));
});

const Organization = () => {
    return (
        <Suspense fallback={null}>
            <Page />
        </Suspense>
    );
};

export default Organization;
